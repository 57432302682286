import React from 'react';
import styled from 'styled-components';
import { animated } from 'react-spring';

export interface StyledExpandableDivProps {
    isExpanded: boolean;
}

const StyledExpandableDiv = styled.div<StyledExpandableDivProps>`
    display: ${p => (p.isExpanded ? 'block' : 'none')};
`;

export type ExpandableDivProps = StyledExpandableDivProps &
    React.HTMLAttributes<HTMLDivElement> & { children: React.ReactNode };

export function ExpandableDiv({ children, isExpanded, ...rest }: ExpandableDivProps): JSX.Element {
    return (
        <StyledExpandableDiv {...rest} isExpanded={isExpanded}>
            {children}
        </StyledExpandableDiv>
    );
}
