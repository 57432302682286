import React from 'react';
import styled from 'styled-components';
import { ITreatmentPrice } from '../../models/Pricing';
import { colors } from '../../styles/config';
import { useI18n } from '../../i18n/useI18n';
import { join, reverse, splitEvery, pipe } from 'ramda';

const Container = styled.div`
    color: ${colors.black};
    padding: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    &:nth-child(2n + 1) {
        background: ${colors.grey2};
    }
    &:nth-child(2n) {
        background: ${colors.grey6};
    }
`;

const StyledPrice = styled.div`
    font-size: 18px;
    strong {
        font-weight: 700;
    }
`;

const Treatment = styled.h3`
    font-size: 18px;
`;

const Description = styled.p`
    font-size: 12px;
    opacity: 0.8;
`;

function formatPrice(price: number): string {
    return pipe(
        // @ts-ignore
        reverse,
        splitEvery(3),
        join(' '),
        reverse
        //@ts-ignore
    )(price.toString(10));
}

export function Price({ treatment, price, description, isFromPrice }: ITreatmentPrice): JSX.Element {
    const { t } = useI18n();

    return (
        <Container>
            <div>
                <Treatment>{treatment}</Treatment>
                {description && <Description>{description}</Description>}
            </div>
            <StyledPrice>
                {isFromPrice && <span>{t('pricing.from')} </span>}
                <strong>{formatPrice(price)}</strong>
                <span> {t('pricing.currency-tag')}</span>
            </StyledPrice>
        </Container>
    );
}
