import { graphql } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import Title from '../components/typography/Title';
import Text from '../components/typography/Text';
import { ITreatmentPriceGroup } from '../models/Pricing';
import { Section } from '../components/Block';
import { PriceTableGroup } from '../components/pricing/PriceTableGroup';
import { RemarkNode } from '../models/RemarkNode';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import { Main } from '../components/Main';

const Note = styled(Text)`
    margin-top: 1rem;
`;

export interface PricingProps {
    data: {
        datoCmsPricingPage: {
            title: string;
            priceTable: ITreatmentPriceGroup[];
            noteNode: RemarkNode;
            seoMetaTags: any;
        };
    };
}

export default function Pricing({ data }: PricingProps): JSX.Element {
    const { title, priceTable, seoMetaTags } = data.datoCmsPricingPage;
    const note = data.datoCmsPricingPage.noteNode.childMarkdownRemark.html;

    return (
        <Section as={Main}>
            <HelmetDatoCms seo={seoMetaTags} />
            <Title level={1}>{title}</Title>
            {priceTable.map(priceGroup => (
                <PriceTableGroup key={priceGroup.title} title={priceGroup.title} prices={priceGroup.prices} />
            ))}
            {note && <Note dangerouslySetSanitizedHTML={note} />}
        </Section>
    );
}

export const query = graphql`
    query PricingPage($language: String!) {
        datoCmsPricingPage(locale: { eq: $language }) {
            title
            priceTable {
                title
                prices {
                    treatment
                    price
                    description
                    isFromPrice
                }
            }
            noteNode {
                childMarkdownRemark {
                    html
                }
            }
            seoMetaTags {
                ...GatsbyDatoCmsSeoMetaTags
            }
        }
    }
`;
